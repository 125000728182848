import Icon from "@ant-design/icons";

const CitationsSvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 768 768">
    <g>
      <path
        d="M737.2,55.3H30.8C13.8,55.3,0,69.1,0,86V682c0,17,13.8,30.7,30.8,30.7h706.4c17,0,30.8-13.8,30.8-30.7V86
		C768,69.1,754.2,55.3,737.2,55.3z M706.4,651.2H61.6V116.8h644.9V651.2z"
      />
      <path
        d="M249.8,393.1c0.9,1,1.3,2.3,1.2,3.6c-3.4,33.3-25.1,81.4-57.2,114.6l32.4,33.2c85.6-54.8,118.7-140.2,118.7-197.2v-99.7
		H168v143.9h78.4C247.7,391.5,249,392.1,249.8,393.1z"
      />
      <path
        d="M505,393.1c0.9,1,1.3,2.3,1.2,3.6c-3.5,34.2-26.5,83.3-57.2,114.7l32.3,33.2C566.8,489.7,600,404.3,600,347.3v-99.7H423.2
		v143.9h78.4C502.9,391.5,504.1,392.1,505,393.1z"
      />
    </g>
  </svg>
);

export const CitationsIcon = (props) => (
  <Icon component={CitationsSvg} {...props} />
);
