const ucas = () => (
  <svg width="100%" height="100%" viewBox="0 0 257 77">
    <g>
      <path
        d="M47.3,44.4c0,9.3-1.4,15.3-4.3,18.3c-2.9,3.1-7.8,4.6-14.7,4.6c-3.8,0-7.3-0.9-10.2-2.5c-2.7-1.6-4.6-3.9-5.8-6.7
		c-1.2-2.8-1.7-7.2-1.7-13.9V1.4H0v43.1c0,7.6,1,14.1,2.8,18.8c2,4.8,5,8.2,9.5,10.5c4.6,2.4,10.1,3.6,16.9,3.6
		c7,0,12.7-1.4,17.1-4.2c4.4-3,7.5-6.7,9.1-11c1.6-4.4,2.3-10.1,2.3-17.8V1.4H47.3V44.4z"
      />
      <path
        d="M120.1,49.5c-1.4,6.3-3.9,10.6-7.4,12.8l0,0c-3.7,3-8.2,4.5-13.4,4.5c-4.4,0-8.3-1.1-12.1-3.3c-3.7-2.2-6.4-5.5-8.2-10
		c-1.8-4.5-2.7-9.8-2.7-16c0-4.7,0.8-9.3,2.3-13.7c1.3-4.2,3.9-7.7,7.6-10.4c3.5-2.5,8.2-3.8,13.8-3.8c4.6,0,8.6,1.2,11.9,3.5
		c3.2,2.4,5.5,6.1,7.1,11.3l0.1,0.4l10.5-2.3l-0.2-0.5c-2-6.8-5.4-11.9-10.5-15.7c-5.1-3.8-11.2-5.6-18.8-5.6
		c-6.4,0-12.2,1.5-17.9,4.5c-5.4,2.9-9.7,7.3-12.6,13c-2.9,5.7-4.3,12.3-4.3,19.9c0,7.1,1.3,13.6,3.9,19.6
		c2.6,6.1,6.5,10.8,11.4,14.1c4.9,3.2,11.4,4.9,19.4,4.9c7.4,0,14-2.1,19.6-6.1c5.3-4.3,9.1-10.4,11.1-18.2l0.1-0.5L120.2,49
		L120.1,49.5z"
      />
      <path
        d="M253.9,44.8L253.9,44.8c-2.2-3.2-5.4-5.8-9.7-7.7c-2.8-1.3-7.9-2.8-16.3-4.7c-9.9-2.2-13.2-4.1-14.3-5.1
		c-1.8-1.4-2.7-3.3-2.7-6c0-3,1.2-5.3,3.7-7.3c2.8-2.1,6.8-3.1,12.6-3.1c5.2,0,9.5,1.2,12.4,3.5c2.8,2.2,4.4,5.4,4.9,10l0.1,0.5
		l10.3-0.8l0-0.5c-0.2-4-1.4-7.9-3.6-11.6c-2-3.3-5.2-5.8-9.7-7.9c-4.1-1.8-9-2.7-14.6-2.7c-5,0-9.5,0.8-13.7,2.6
		c-4.3,2-7.2,4.4-9.3,7.5c-2.2,3-3.3,6.5-3.3,10.4c0,3.4,0.8,6.5,2.6,9.4c1.9,2.8,4.7,5.1,8.1,6.9c3.2,1.5,8.4,3.1,14.3,4.5
		c8.4,2.1,11.9,3.2,13,3.6c3,1,5.2,2.5,6.4,4.1c1.2,1.5,1.9,3.4,1.9,5.5c0,2-0.7,4-2.1,5.9c-1.4,1.9-3.4,3.3-6,4.3
		c-2.8,1-6,1.5-9.7,1.5c-4.2,0-7.7-0.7-11.1-2c-3.3-1.6-5.6-3.4-7.2-5.5c-1.6-2.3-2.6-5.2-3-8.7l0-0.5l-10.1,0.8l0,0.5
		c0.2,5,1.5,9.3,4.1,13.8c2.9,4,6.4,6.8,10.8,8.7c4.4,2,10,2.9,16.9,2.9c5.3,0,10.1-1,14.2-2.9c4.4-2.1,7.7-4.8,9.9-8.2
		c2.3-3.3,3.5-6.9,3.5-11.1C257,51.4,256,48,253.9,44.8z"
      />
    </g>
    <path
      fill="#E00023"
      d="M172.6,7.7c-1.3-3.8-4.7-6.3-8.7-6.3c-3.7,0-7.1,2.4-8.7,6.3l-25.5,69.1l-0.2,0.7h10.6l23.2-63.1
	c0.1-0.3,0.4-0.3,0.5-0.3s0.4,0,0.5,0.3l23.8,62.7l0.1,0.3h10.6L172.6,7.7z"
    />
  </svg>
);

export default ucas;
