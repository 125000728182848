const scopus = () => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" fill="#FF8200">
    <path
      d="M0,0v512h512V0H0z M135.8,440.7c-39.4,0-68-5.9-96.1-18.9l6.5-42.7c27.5,16.7,51.3,24.8,87.5,24.8
		c36.7,0,67.5-24.8,67.5-59.4c0-35.6-34.6-49.1-67.5-69.1c-44.3-27-92.9-50.8-92.9-105.9s40.5-98.3,106.9-98.3
		c46.4,0,67,5.4,95.6,17.3l-2.7,42.7c-30.8-18.4-55.1-23.8-90.2-23.8c-40.5,0-61,30.8-61,58.9c0,37.8,36.2,51.8,70.2,72.4
		c43.7,25.9,89.1,49.1,89.1,100.5C248.7,406.7,190.9,440.7,135.8,440.7z M388.6,440.2c-64.8,0-111.3-45.4-111.3-131.2
		c0-89.6,61.6-126.9,111.3-126.9c44.8,0,78.3,11.3,81.5,43.7c0.5,4.9,0.5,11.9,0.5,17.8h-38.9c0-21.1-11.9-25.9-41-25.9
		c-16.7,0-25.9,2.2-29.7,4.3c-4.3,2.2-38.3,20-38.3,85.3c0,68.6,41,96.7,77.2,96.7c20.5,0,52.4-11.3,69.1-24.8l3.2,37.8
		C446.9,433.7,417.2,440.2,388.6,440.2z"
    />
  </svg>
);

export default scopus;
