const ucas = () => (
  <svg width="100%" height="100%" viewBox="0 0 291 90" fill="#192B6B">
    <g>
      <g>
        <path
          d="M0,0h13.4v35.9c0,5.7,0.2,9.4,0.5,11.1c0.6,2.7,1.9,4.9,4.1,6.5c2.2,1.6,5.1,2.5,8.8,2.5
			c3.8,0,6.7-0.8,8.6-2.3c1.9-1.6,3.1-3.5,3.5-5.7c0.4-2.3,0.6-6,0.6-11.3V0h13.4v34.8c0,8-0.4,13.6-1.1,16.9
			c-0.7,3.3-2.1,6.1-4,8.3c-1.9,2.3-4.5,4.1-7.8,5.4c-3.3,1.3-7.5,2-12.7,2c-6.3,0-11.1-0.7-14.4-2.2c-3.3-1.5-5.8-3.4-7.7-5.7
			c-1.9-2.3-3.1-4.8-3.7-7.3C0.4,48.4,0,42.8,0,35.3V0z"
        />
        <path d="M61,66.3V0h13.4v29.4l27-29.4h18L94.4,25.8l26.3,40.5h-17.3L85.2,35.2L74.4,46.2v20H61z" />
        <path
          d="M124.4,66.3V0h28.1c7.1,0,12.2,0.6,15.4,1.8c3.2,1.2,5.8,3.3,7.7,6.4c1.9,3,2.9,6.5,2.9,10.4
			c0,5-1.5,9.1-4.4,12.3c-2.9,3.2-7.3,5.3-13.1,6.1c2.9,1.7,5.3,3.5,7.2,5.6c1.9,2,4.4,5.6,7.6,10.8l8.1,12.9h-16l-9.7-14.4
			c-3.4-5.2-5.8-8.4-7-9.7c-1.3-1.3-2.6-2.3-4-2.8c-1.4-0.5-3.7-0.7-6.7-0.7h-2.7v27.7H124.4z M137.7,28h9.9c6.4,0,10.4-0.3,12-0.8
			c1.6-0.5,2.8-1.5,3.7-2.8c0.9-1.3,1.4-3,1.4-5c0-2.2-0.6-4-1.8-5.4c-1.2-1.4-2.9-2.2-5-2.6c-1.1-0.1-4.3-0.2-9.8-0.2h-10.4V28z"
        />
        <path d="M188.9,66.3V0.5h13.4v54.6h33.2v11.2H188.9z" />
        <path
          d="M240.3,66.3V0h21.4c8.1,0,13.4,0.3,15.9,1c3.8,1,7,3.2,9.5,6.5c2.6,3.3,3.8,7.6,3.8,12.9
			c0,4.1-0.7,7.5-2.2,10.3c-1.5,2.8-3.3,5-5.6,6.5c-2.3,1.6-4.6,2.6-6.9,3.1c-3.2,0.6-7.8,1-13.9,1h-8.7v25H240.3z M253.7,11.2V30
			h7.3c5.3,0,8.8-0.3,10.6-1c1.8-0.7,3.2-1.8,4.2-3.3c1-1.5,1.5-3.2,1.5-5.2c0-2.4-0.7-4.4-2.1-6c-1.4-1.6-3.2-2.5-5.4-2.9
			c-1.6-0.3-4.8-0.5-9.6-0.5H253.7z"
        />
      </g>
    </g>
    <rect x="0.1" y="73.6" width="290.9" height="16.4" />
  </svg>
);

export default ucas;
