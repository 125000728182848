const isni = () => (
  <svg width="100%" height="100%" viewBox="0 0 256 155">
    <path
      fill="#312783"
      d="M256,18.8c0,39.2,0,78.3,0,117.5c-0.1,0.5-0.2,0.9-0.2,1.4c0,1.5-0.3,2.9-0.6,4.3c-1.6,6.2-5.5,10.2-11.6,12
		c-2.4,0.7-4.8,1-7.2,1c-16.6,0-33.3,0-49.9,0c-21.2,0-42.4,0-63.6,0c-34.3,0-68.7,0-103,0c-1.7,0-3.5-0.1-5.2-0.4
		c-3.1-0.6-6-1.7-8.4-3.8c-3.3-2.8-5-6.5-5.7-10.7c-0.2-1.3-0.4-2.5-0.6-3.8C0,97.1,0,58,0,18.8c0.1-0.4,0.2-0.7,0.3-1.1
		c0.3-1.5,0.4-3,0.8-4.5C2.1,8.8,4.4,5.3,8.3,2.9c2.3-1.4,4.8-2.1,7.4-2.5c0.9-0.1,1.8-0.3,2.7-0.4c73.1,0,146.2,0,219.3,0
		c0.3,0.1,0.7,0.1,1,0.2c1.3,0.2,2.6,0.3,3.9,0.6c4.4,1,7.9,3.3,10.4,7.2c1.7,2.8,2.6,5.9,2.8,9.2C255.8,17.7,255.9,18.3,256,18.8z
		 M116.3,69.1c-1.2-4.1-2.6-7.8-4.9-11.1c-1.9-2.7-4.2-4.8-7.2-6.2c-7.8-3.8-16-5.3-24.6-4.5c-5.6,0.5-10.9,2-16,4.5
		c-5.4,2.6-9,6.8-10.6,12.6c-1,3.5-1.3,7-0.8,10.6c1.1,7.3,4.9,12.7,11.7,15.8c2.7,1.3,5.6,2.4,8.5,3.2c7.3,2.1,14.6,4.1,22,6.1
		c2.3,0.6,4,2.4,4.2,4.7c0.3,2.8-0.7,5-3.1,6.5c-3.6,2.3-7.6,3.2-11.8,2.6c-6.3-0.9-10.8-4.2-13.7-9.9c-0.1-0.2-0.2-0.5-0.3-0.7
		c-0.7-1-1.5-1.7-2.8-1.4c-0.6,0.1-1.3,0.2-1.9,0.3c-4.2,0.6-8.4,1.2-12.6,1.9c-1,0.1-2,0.3-3.1,0.5c0.1,0.4,0.2,0.7,0.3,1
		c0.5,1.5,1,3,1.7,4.5c3.3,7.4,8.7,12.7,16.3,15.5c4.8,1.8,9.7,2.7,14.8,2.9c5.9,0.3,11.7-0.4,17.4-2.1c7.9-2.3,13.7-7.2,17-14.7
		c3.4-7.5,2.8-14.8-2-21.6c-2.6-3.7-6.2-6.1-10.4-7.8c-5.6-2.3-11.4-3.9-17.2-5.6c-4.2-1.2-8.4-2.5-12.6-3.8c-1.3-0.4-2.3-1.3-3-2.4
		c-0.9-1.3-0.9-2.7-0.1-4.1c0.9-1.5,2-2.5,3.8-2.8c4-0.7,8.1-1,12.1-0.9c4.1,0.1,7.4,2.5,8.8,6.4c0.2,0.5,0.4,1,0.6,1.4
		c0.7,1.2,1.7,1.7,3.1,1.4c1.1-0.2,2.2-0.4,3.3-0.6c3.5-0.6,7-1.2,10.5-1.7C114.6,69.4,115.4,69.2,116.3,69.1z M152.4,125.9
		c0-0.5,0-0.8,0-1.2c0-2.3,0-4.6,0-6.9c0.1-7.6,0.2-15.2,0.3-22.9c0.1-6.1,0.3-12.2,0.6-18.2c0.3-5.3,3.1-9.1,7.8-11.5
		c1.8-0.9,3.6-1.5,5.6-1.7c4.4-0.5,8.3,1.1,10.7,5.1c0.8,1.3,1.3,2.6,1.6,4.1c0.7,3.3,0.9,6.7,0.9,10c0.1,10.8,0,21.6,0,32.4
		c0,2.8,0,5.5,0,8.3c0,0.8,0.1,1.6,0.2,2.4c1.4,0.2,19.2,0.1,19.9-0.1c0-0.3,0-0.7,0-1.1c0-8.6,0-17.1,0-25.7c0-5.4,0-10.9-0.1-16.3
		c0-3.5-0.1-6.9-0.3-10.4c-0.1-2.6-0.3-5.2-0.9-7.8c-1.4-6.3-4.8-11.2-10.7-14c-4.9-2.4-10.1-3.5-15.5-3.1
		c-6.7,0.6-12.5,3.2-17.4,7.9c-1.2,1.2-2.5,2.4-3.7,3.6c-0.1,0.1-0.3,0.2-0.6,0.4c0-3.2,0-6.3,0-9.3c-0.9-0.2-17.6-0.2-18.4,0
		c-0.2,1.2-0.1,75.2,0.1,75.9C139.1,125.9,145.7,125.9,152.4,125.9z M20.9,126c6.6,0,13,0,19.4,0c0.2-1.2,0.2-75.3,0-76
		c-6.4,0-12.9,0-19.4,0C20.9,75.3,20.9,100.6,20.9,126z M235.2,125.9c0.2-0.9,0.2-75.1,0-75.9c-6.4,0-12.9,0-19.3,0
		c0,0.2-0.1,0.2-0.1,0.3c0,25,0,49.9,0,74.9c0,0.2,0,0.4,0.1,0.6c0,0,0,0.1,0.1,0.1c0,0,0,0,0.1,0.1
		C222.4,125.9,228.8,125.9,235.2,125.9z M40.5,38.3c0-5.9,0-11.7,0-17.5c-0.9-0.2-18.8-0.2-19.6,0c0,5.8,0,11.6,0,17.5
		C27.5,38.3,33.9,38.3,40.5,38.3z M215.9,38.4c6.5,0,13,0,19.4,0c0.2-0.8,0.2-16.7,0-17.6c-6.4,0-12.9,0-19.4,0
		C215.9,26.7,215.9,32.5,215.9,38.4z"
    />
  </svg>
);

export default isni;
