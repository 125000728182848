const openalex = () => (
  <svg width="100%" height="100%" viewBox="0 0 52 70" fill="#5F5F5F">
    <path
      d="M31.12,30.4c0-1.8,0-3.4,0-5.09c-1.92-1.16-3.88-2.34-5.88-3.55c-2.25,1.27-4.47,2.52-6.69,3.78
		c-2.6,1.47-5.21,2.92-7.8,4.42c-0.89,0.52-1.69,0.55-2.6,0.02c-2.38-1.39-4.79-2.72-7.2-4.05c-0.66-0.36-0.94-0.89-0.94-1.62
		c0.01-2.89,0.01-5.78,0-8.67c0-0.79,0.35-1.31,1.02-1.68C8.74,9.59,16.45,5.22,24.16,0.84c0.77-0.44,1.48-0.44,2.25,0.01
		c7.49,4.36,14.98,8.71,22.48,13.05c0.78,0.45,1.12,1.04,1.12,1.94C49.99,27.89,49.99,39.94,50,52c0,0.82-0.35,1.35-1.05,1.77
		c-7.48,4.43-14.96,8.88-22.43,13.33c-0.78,0.46-1.55,0.55-2.35,0.09C16.5,62.8,8.84,58.4,1.17,54.03c-0.87-0.5-1.17-1.14-1.16-2.1
		c0.03-2.96,0.03-5.91,0-8.87c-0.01-0.97,0.32-1.61,1.17-2.1c7.58-4.39,15.15-8.81,22.71-13.24c1.02-0.6,1.9-0.55,2.87,0.08
		C28.15,28.68,29.58,29.49,31.12,30.4z M47.17,15.71c-7.45-4.32-14.7-8.53-21.91-12.72C17.58,7.35,9.99,11.66,2.44,15.95
		c0,2.73,0,5.38,0,8.05c2.17,1.21,4.31,2.41,6.64,3.71c0-2.88,0-5.46,0-8.1c5.45-2.99,10.86-5.95,16.52-9.05c0,3.12,0,5.9,0,8.6
		c2.39,1.45,4.65,2.85,6.95,4.19c0.76,0.44,1.07,0.99,1.06,1.85c-0.03,2.26,0.03,4.52-0.04,6.78c-0.02,0.54-0.22,1.34-0.59,1.53
		c-0.43,0.22-1.22,0.05-1.7-0.22c-2.03-1.12-4-2.34-6.01-3.53C17.6,34.2,10,38.62,2.44,43.03c0,3.04,0,5.98,0,8.94
		c7.45,4.26,14.86,8.49,22.45,12.84c0-3.14,0-6.02,0-8.91c5.01-3.08,9.95-6.12,14.97-9.2c0-9.04,0-18.06,0-27.11
		C42.27,18.31,44.61,17.07,47.17,15.71z"
    />
    <path
      d="M32.98,42.46c-2.79,1.71-5.27,3.22-7.95,4.86c-2.59-1.43-5.37-2.96-8.16-4.5c0-0.13-0.01-0.26-0.01-0.39
		c2.76-1.52,5.52-3.03,8.22-4.51C27.7,39.42,30.23,40.88,32.98,42.46z"
    />
  </svg>
);

export default openalex;
